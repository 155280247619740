<template>
  <div class="check-out-success">
    <div class="check-out-success__content">
      <h1>{{ $t('check_out.fail.content.title') }}</h1>

      <ProfileDetail />
      <LanguageSelect absolutePosition />

      <p>
        {{ $t('check_out.fail.content.return_card_info') }}
      </p>
    </div>
    <div class="check-out-success__actions">
      <ButtonInput @click="$router.push({name: 'home'})">
        {{ $t('action.button.logout_exit') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect/index';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import ProfileDetail from '@/components/ProfileDetail';

export default {
  name: 'CheckOutError',
  components: {
    ButtonInput,
    LanguageSelect,
    ProfileDetail,
  },
};
</script>

<style lang="scss">
  .check-out-success {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;

    h1 {
      font-size: 3rem;
    }

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-top: .5rem;
    }

    &__content {
      margin-bottom: 2rem;
    }

    &__actions {
      display: flex;
      justify-content: center;

      .button {
        flex: 0 50%;
        margin: 0 1rem;
      }
    }
  }
</style>